@import "../../common/scss/_variables.scss";
.home-container{

  .first-content p{
    color:#fff;
  }
.carousal-content {
  width: $full-margin-width;
  display: flex;
}
.button-container{
  background-color: transparent;
  border-width: 0px;
  position: absolute;
  top:0px;
  right:5px;
  margin:10px;
}
.in-text{
  //height: 10%;
 /// width: 10px;
}
.know-more-button-content{
    margin-top:25px;
    text-align: right;
  }
.img-content{
  width: $full-margin-width;
  height: $full-margin-width;
 // display: inline-block;
  background-color: black;
}

.header-container{
  margin:auto;
}

.margin-auto{
 
  margin: auto;
  color: #fff;
}

.second-content{

 
}
.tile-content {
 // position:'absolute';
 // bottom:15px;
  font-size:14px;
  font-weight: 500px ;
  color:#3CB6E9;

  text-align: center
}

.image div.test{

  height:400px;
  padding:0px 10px;
  border-radius:10px;
}

 .elementor-spacer-inner {
   height: 300px;;
}

.feature-icon {
  width:100%;
  height:100%;
  height:400px;
  object-fit: cover;
  border-radius: 10px;
  // padding: 10px;
  padding-top: 300px;
 // justify-content: flex-end;
}

.heading-title{
  margin:20px 0px;
}

.second-content .heading-title{
  margin-top: 47px;
  margin-bottom: 28px !important;
}
h1{
  font-size: 34px;
}
.layout {
  background-attachment: fixed;  
}

.first-content,.second-content{
  width:$margin-width-eight;

  margin:0px auto 0px auto;
 
}

.second-content{
  margin-bottom:37px;
}
.first-content p{
  width:80%;    
}

.second-content{

}

.third-content{
 
  width:90%;
  margin:auto;
  margin-bottom: 35px;
}



.heading-title-black{
    color:#000000;
    margin-bottom: 20px;
    margin-top: 28px;
  font-size: 34px;

 
}


 .heading-title-white{
  color:#fff;
  color:#fff;
 }
.carousel .slide .legend {
  top: 10% !important;
  left: 17% ;
  background: none !important;
  text-align: left !important;
  margin-left:0px !important;
  opacity: 1 !important;
}

.carousel .slide .legend img {
  width:50% ;
}

.carousel .slide .legend{
 
}

.first-section{
    background-repeat: no-repeat;
    width: 100%;
    background-position: center center;
    background-size: cover;
}

.elementor-spacer-inner-hieght{
  height:92px;
}

.tile-content span{
  width:150px;
  display:inline-block;
}
@media only screen and (max-width: 600px) {
  .first-content p{
    width:100%;    
  }
  .carousel .slide .legend{
    left:7%;

  }
  .third-content .feature-icon{
  
    margin-bottom:20px;
  }
  .carousel .slide .legend img {
  // height: 40%;
  width: 100%;
}
  .first-content,.second-content{
    width:80%;
    margin: auto;
  }

  .elementor-spacer-inner-hieght {
    height: 10px;
  }
  
  .elementor-spacer-inner {
    height: 96px;
  }
}


@media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
      .first-content p{
        width:100%;    
      }
      .carousel .slide .legend{
        left:7%;
    
      }
      .third-content .feature-icon{
      
      margin-bottom:20px;
      }
    
      .first-content,.second-content{
        width:80%;
        margin: auto;
      }
    
      .elementor-spacer-inner-hieght {
        height: 10px;
      }

      .elementor-spacer-inner {
        height: 72px;
    }
}

.inoovation-item{
  
    cursor: pointer !important;
    pointer-events: auto;
  
}

}