@import "../../common/scss/variables";
.contact-us-container{
    .contact-us-image{
      width:100%;
    }
   
    img{
        width:100%
    }
    .press-text {
        // height: 80vh;
        // width: 100%;
      
        position: absolute;
      
        display: flex;
        font-size: 37px;
        left: 18%;
       // font-weight: 600;
        color:#fff;
        letter-spacing:0;
        
      }
    .location-title{
        color: #000000;
  
        font-size: 35px;
        font-weight: 400;
        letter-spacing: 0.2px;
        }
        .text-container-section{
            float: none;
            margin: auto;
        }

        .element-divider{
            border-top:1px solid rgba(84, 89, 95, 0.2);
            margin:20px
        } 

        .address{
            margin:0;
            font-family: $MBCorpoTextRegular;
            color: #000000;
            letter-spacing: 0px;
            font-weight:600;
            font-size: 18px;
            margin-bottom:18px
        }

        p{
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2em;
            letter-spacing: 0.3px;
        }

        .box-shadow {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
            padding:1px 10px;
            padding: 11px 1px;
        }

        .address1{
            margin-bottom:20px;
        }

        .element-divider-content{
            margin-top: 54px;
        }
        .email-link:hover{
         text-decoration: none
        }
        @media only screen and (max-width: 600px) {
          .address{
              margin-top:20px;
          }
        }
    
}