

@font-face {
    font-family: "MB Corpo S Text Regular";
    src: url("../fonts/MBCorpoSText-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MB Corpo S Title Regular";
    src: url("../fonts/MBCorpoSTitle-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }


$MBCorpoSText: "MB Corpo S Text Regular";
$MBCorpoSTitle: "MB Corpo S Title Regular";