.csr-container {
  .goal-image-contanier {
    margin: auto;
    .col-lg-2 {
    }
  }
  img {
    width: 100%;
  }
  .csr-image {
    width: 100%;
  }
  .benifits-image {
    width: 100%;
  }
  .press-text {
    // height: 80vh;
    // width: 100%;

    position: absolute;
    top: 20%;
    display: flex;
    font-size: 37px;
    left: 18%;
    // font-weight: 600;
    color: #fff;
    letter-spacing: 0;
  }
  .policy-text {
    // height: 80vh;
    // width: 100%;

    position: absolute;
    top: 35%;
    display: flex;

    left: 18%;
    color: #fff;
  }
  .text-container-section {
    float: none;
    margin: auto;
    img {
      margin-bottom: 20px;
      width: 100%;
    }

    h2 {
      // margin-top: 36px;
      // margin-bottom: 20px;
    }

    p {
      font-size: 15px;
      margin: 0 0 20px;
      color: #000000;
    }
  }

  .first-box {
    margin-top: 38px;
  }
  @media only screen and (max-width: 600px) {
    .policy-text {
      top: 25%;
      font-size: 25px;
      left: 10%;
    }

    .banner-content .img-content {
      display: flex;
      background-color: #fff;
      width: 100%;
      height: 300px;
    }

    .goal-image-contanier {
      img {
        width: 60%;
      }
      text-align: center;
    }
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .innovation-content {
      padding-left: 15vh;
    }
    .press-text {
      top: 10%;
      font-size: 25px;
    }
    .policy-text {
      top: 26%;
    }
  }
}

.csr-banner-container {
  .category-text {
    font-size: 20px;
  }
  img {
    width: 100%;
  }
  h2,
  p {
    color: #fff;
  }
  position: relative;
  .banner-content {
    position: absolute;
    top: 38px;
    left: 20%;
    width: 37%;
  }

  .banner-title {
    font-size: 37px;
  }
  .btn-container{
    position: relative;
    right: 6%;
    display: flex;
    justify-content: space-evenly;
  }

  .hiring-title {
    font-size: 63px;
    margin-top: 36px;
  }
  @media only screen and (max-width: 600px) {
    .banner-image {
      height: 279px;
    }
    .banner-content {
      position: absolute;
      top: 30px;
      left: 4%;
      width: 100%;
    }
    .btn-container{
      position: relative;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

    }
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .banner-image {
      height: 279px;
    }
    .banner-content {
      position: absolute;
      top: 30px;
      left: 4%;
      width: 70%;
    }
    .btn-container{
      right: 13%;
    }
  }
}
