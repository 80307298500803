.banner-content {
  .img-content {
    display: flex;
    background-color: #fff;
    width: 100%;
  }
 
  .press-text {
    position: absolute;
    margin-top: 50px;
    display: flex;
    font-size: 37px;
    left: 20%;
    color: #fff;
    letter-spacing: 0;
  }
  .details-text{
    position: absolute;
    margin-top: 160px;
    display: flex;
    font-size: 15px;
    left: 20%;
    color: #fff;
  }

  @media only screen and (max-width: 600px) {
   

    .press-text{
        margin-top: 50px !important;;
        left:5% !important;
    }

}
}
