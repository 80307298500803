@import "../../common/scss/variables";
.support-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color:#000;
    // align-items: center;
    justify-content: center;
    img{
        width: 100%;
      }


    .support-content{
        background-color: #514D4D;
        display: inline;
        width: 80%;
        // height: 150%;
        padding: 15px;
        margin-inline: 10%;
        margin-top: 10%;
    }
    ul{
        margin-top: 10px;
    }
    li{
        //  width: 300px;
          font-size: 16px;
          color: #fff;
      }
    .img-sustain{
        display: block;
        width: 100%;
        // height: 150%;
        height: 50vh;
        padding: 5px;

    }
    .date-text{
        font-weight: 500;
        font-size: 16px;
        color: #fff;
    }
    .img-icon{
        margin: 20px;
        width: 3.8%;
    }
    .email-link{
        font-weight: 600;
    }
    .mail-content{
        display: block;
    }
    @media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
        .practise-tile {
            // height: 130vh
        }
    }
    @media only screen and (max-width: 600px) {
        .mail-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-block: 20px;
            text-align: center;
            font-size: 11px;
        }
        .img-icon{
            margin: 20px;
            width: 13%;
        }
        .head{
            display:none ;
        }
        .agenda-text{
            display:none ;
        }
        .speaker-item{
            display: block;
        }
        .title-name{
            font-size: 25px;
        font-weight: 600;
        color: #fff;
        display: block;
        text-align: center;
        }
        .img-sustain {
            height: 100%;
        }

        .date-text{
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 20px;
            
        }
        .topic-text{
            font-size: 15px;
            font-weight: 600;
        }
        li{
            //  width: 300px;
              font-size: 14px;
              font-weight: 600;
          }
           td{
             padding-left: 10px;
             color: #fff;
            
         }
    }
}