@import "../../common/scss/variables";
.hiring-container{
    .banner-parent-conetnt{
        position:relative;
        img{
            width:100%
        }

        .banner-title{
            font-size: 37px;
        }
    }

    .banner-content{
        p,h1{
            color:#fff;
        }
        position: absolute;
        top: 38px;
        left: 20%;
        width: 41%;
       
      }
    display: flex;
    flex-wrap: wrap;
    .press-text {
        // height: 80vh;
        // width: 100%;
        position: absolute;
      margin-top: 50px;
        display: flex;
        font-size: 37px;
        left: 20%;
        color: #fff;
        letter-spacing:0;
       
        
    }
    .carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    background-color: transparent;
    right: -146px;
    margin: 200px;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    margin: 200px;
    left: -146px;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}

.carousel .control-next.control-arrow {
   
   // height: 20px;
    border: solid #00ccff;
    border-width: 0 4px 4px 0;
    width: 20px;
    height: 20px;
    display: inline-block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow {
    content: '';
    border: solid #00ccff;
    border-width: 0 4px 4px 0;
    display: inline-block;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(135deg);
}
    // .carousel.carousel-slider .control-arrow{
    //    // top: 50%;
    //     background: linear-gradient(45deg, black, transparent);
    // }
    .react-multi-carousel-dot-list{
        padding-top: 120px;
    }
    .carousel-container{
        padding-inline: 10px;
    }
    .bannerWidth{
    width: 30%;
    }
    .press {
        height: 100%;
       
    }
    .text-container-section{
        float: none;
        margin: auto;
        margin-block: 10px;
    }
    .about-title{
        font-size: 25px;
        letter-spacing: 0.01em;
        font-weight: 500;
        margin-left: 15px;
    }
    // .img-content{
    //     width: $medium-margin-width;
    //     height: $medium-margin-width;
    //    // display: inline-block;
    //     background-color: black;
    //   }
    .img-content{
      width: 100%;
    }
    .img-carousel{
        display: flex;
        padding: 5%;
        padding-inline: 20%;
        background-color: #fff;
    }
    .hiring-img{
        // height: 40vh;
        // width: 20vh;
    }
    .para-content {
       // margin: 20px
    }

    .first-content, .second-content {
        // margin: auto;
    }
    @media only screen and (max-width: 600px) {
        .bannerWidth{
            width: 100%;
            font-size: 19px;
            }  
            .carousel.carousel-slider .control-arrow{
               bottom: 30%;
               top: 0;
            }
            .carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
                margin-top:50px;
            }
            .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
                margin-top: 50px;
            }

            .banner-parent-conetnt{
               
                .banner-content{
                    position: absolute;
                    top: 2px;
                    left: 4%;
                    width: 90%;
                }
                .banner-title{
                    font-size: 17px;
                }
                .banner-text{
                    font-size: 11px;
                }
            }
            
    }
    @media screen and (min-device-width: 768px)
        and (max-device-width: 1024px) {
            .press-text{
                left: 15%;
            }
            .bannerWidth{
                width: 100%;
                }
                .carousel.carousel-slider .control-arrow{
                    bottom: 30%;
                    top:0
                 }
                 .banner-content{
                    top: 38px;
                    left: 10%;
                    width: 70%;
                }
                .banner-title{
                    font-size: 30px;
                }
                .banner-text{
                    font-size: 20px;
                }
        }

        .carousel .control-next.control-arrow:before {
             border-left: none;
        }

        .carousel .control-prev.control-arrow:before {
            border-right:none;
        }

        
}