.news-container {
  background-color: black;
  // height: 40vh;
  width: 100%;
  margin: auto;
  padding-top: 10px;

  .carousel .control-next.control-arrow,
  .carousel .control-next.control-arrow:hover {
    background-color: transparent;
    // right: 100px;
    margin: 0px 200px;
  }

  .carousel .control-prev.control-arrow,
  .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    margin: 0px 200px;

    // left: 100px;
  }
  .flex-title {
    color: #ffffff;
    text-align: center;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  .news-content {
    //  height: 20vh;
    display: inline-block;
    // width: 20vh;
    //  display: flex;
    margin: 20px;
    // align-items: center;
    justify-content: center;
    border-color: #000;
    background-color: #000;
    border-width: 0px;
  }
  .news-tile {
    // height: 180px;
    // width:60px;
    // max-width: 50%;
  }
  .section {
    height: 10%;
    justify-content: center;
  }
  .footer-button {
    width: 20%;
    // margin: auto;
    // margin-top: 150px;
    display: flex;
    margin-left: 40%;
    // flex-direction: row;
    // width: 100%;
    // height: 80px;
    // align-items: center;
    // justify-content: center;
  }
  .button-content {
    margin-top: 20px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #3cb6e9;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    height: 40px;
    width: 100%;
    font-family: "MBCorpo Text-Regular", Sans-serif;
    font-weight: 500;
    line-height: 0.5em;
    background-color: #ce616100;
  }
  .section-area {
    // height:50%;
    // width:100%;
  }
  @media only screen and (max-width: 1200px) {
    .footer-button {
      width: 80%;
      margin: auto;
      // margin-left: 0px;
      display: block;
    }
    .button-content {
      width: 100%;
    }
    .carousel .control-next.control-arrow,
    .carousel .control-next.control-arrow:hover {
      margin: 0px;
      top: 0px;
    }

    .carousel .control-prev.control-arrow,
    .carousel .control-prev.control-arrow:hover {
      margin: 0px;
      top: 0px;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .carousel .control-next.control-arrow,
    .carousel .control-next.control-arrow:hover {
      margin: 0px;
    }

    .carousel .control-prev.control-arrow,
    .carousel .control-prev.control-arrow:hover {
      margin: 0px;
    }
  }
}

.ie {
  .carousel .control-next.control-arrow,
  .carousel .control-next.control-arrow:hover {
    background-color: transparent;
    // right: 100px;
    margin: 0px 180px;
  }

  .carousel .control-prev.control-arrow,
  .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    margin: 0px 180px;

    // left: 100px;
  }
}
