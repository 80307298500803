
.join-star-container{
  .what-we-do-link{
    color:#00ccff;
    text-decoration:underline;
    cursor: pointer;
  }
  .click-here{
    font-weight: bold;
  }
  p{
    letter-spacing: 0.2px;
  }
  .banner-parent-conetnt{
    .category-text{
      font-size:20px
    }
    img{
      width: 100%;
    }
    h2,p{
      color:#fff;
    }
    position: relative;
    .banner-content{
      position: absolute;
      top: 38px;
      left: 20%;
      width: 37%;
    }

    .banner-title{
      font-size: 37px;
    }

    .hiring-title{
      font-size: 63px;
      margin-top:1.5em;
    }
    @media only screen and (max-width: 600px) {
      .banner-image{
        height: 500px;
      }
      .banner-content{
        left: 5%;
        position: absolute;
        top: 30px;
        width: 92%;
    
       
      }
    }

    @media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
      .banner-image{
        height: 500px;
      }
      .banner-content{
        position: absolute;
        top: 30px;
        left: 2%;
        width: 70%;
      }
    }
   
  }
 
  .join-center-container{
    h2{
      font-size: 34px;
      margin:25px 0px;
    }
    p{
      color: #4A4747;
      letter-spacing: 0.2px;
    }
  .opp-section-div{
    margin:auto;  
    float: none
  }
  span{
    font-weight: bold
  }
  ul{
    font-size: 17px;
    line-height: 1.6em;
    letter-spacing: 0.2px;
    padding: 15px;
    color:#000000;
  }
  .toggle-icon{
    position: absolute;
    right: 0;
    height: 100%;
    width: 60px;
    cursor: pointer;
    color: #555;
    font-size: 18px;
    text-align: center;
  }

  .accordian{
    .sub-text{
      display: inline-block;
    
      color: #2E2D2D;
    }
    ul{
      list-style-type: none;
    }
    
    .links 
        { 
          li.questions-li:last-child{
           border-bottom: 4px solid #F8F8F8 !important;
        }

        padding:0;
    }
    ul.links{
    
      li.questions-li{
      
        border: 4px solid #F8F8F8;
        /* border-width: 4px; */
        position: relative;
        border-bottom: 0px solid #F8F8F8;
        .toggle-row{
          padding:15px 4px;
          cursor: pointer;
          p.question-title{
            line-height: 1.2;
            color:#000000 !important;
            font-size:18px;

          }
        }
        
      }

      a{
            padding: 15px 15px;
            display: inline-block;
            font-size: 17px;
            font-weight: 300;
            color:#000;
            text-decoration: none;
            width:100%;
            cursor: pointer;
      }
    }

    span.toggle-questions{
      position: absolute;
      right: 0;
    
      height: 100%;
      width: 60px;
      cursor: pointer;
      color: #555;
      font-size:18px;
      text-align: center;
  }

  .subLinks {
    margin:0;
    padding:0;
    li{
      border-top: 4px solid #F8F8F8;
      padding: 20px 15px;
    }
  }
  }

  .job-link{
    float: none;
    margin: auto;
    /* margin-top: 20px; */
    padding: 50px 15px;
  }

  .job-link-text{
    color:#fff;
    font-size: 20px;
  }
 
  .lob-link-clickhere a{
    color:#fff;
    cursor: pointer;
    font-weight: bold
  }

  .job-application-section {
    float:none;
    margin: auto
  }

  .job-steps{
    padding: 44px;
    p{
    margin:20px;
    }

    img{
      width:100%
    }
  }  

  .opp-link{
    img {
      width:100%;
      transition: all 0.2s ease-in;
      cursor: pointer;
    }

    img:hover{
      transform: scale(1.2) rotate(0deg)
    } 
  }
  }

  
.title{
  font-size: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 600;   
}
.content{
  margin-top: 20px;
}

}