@import "../../common/scss/_variables.scss";

.email-text-content{
    font-family:$MBCorpoTextRegular;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    text-align: center;
    margin-bottom:20px;
}

.email-link{
    color:#15c0eb
}