@import "../../common/scss/variables";
.event-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color:#000;
    // align-items: center;
    justify-content: center;
    img{
        width: 100%;
      }
      table {
        width: 100%;
        background-color: #575556;
        
        
    }
    thead{
        text-align: center;
        width: 100%;
        color: #fff;
       // align-items: center;
    }
    td{
       // padding-inline: 20px;
        color: #fff;
       
    }
    th{
       // padding-inline: 30px;
        align-items: center;
        color: #00DAFB;
        width: 50%;
        text-align: left;
    }
    tr{
        
        border-bottom: 1px solid transparent;
    }
    .contents{
        border-bottom: 1px solid #fff;
        margin-left: 0px;
        margin-right: 0px;
    }
    .row-content{
        border-bottom: 1px solid transparent;
    }
    li{
      //  width: 300px;
        font-size: 16px;
        color: #fff;
        padding-bottom: 10px;
    }
    ul{
        padding: 5px;
    }
    .header-table{
        font-size: 17px;
        // padding-left: 50px;
        border-bottom: 1px solid transparent;
        height: 15vh;
       
    }
    .first-column{
        padding-left: 0px;
    }
    .second-column{
        padding: 0px;
    }
    .parent-item{
        display: flex;
        flex-direction: row;
    }
    .time-text{
        color: #00DAFB;
        font-size: 15px;
        font-weight: 600;
        margin: 20px ;
    }
    .head{
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid white;
    }
    .second-border{
        border-bottom: 1px solid transparent;
        width: 100%;
    }
    .table-item{
        flex-direction: column;
    }
    .img-content{
        width: 100%;
    }
    .box-shadow {
       box-shadow: 0px 0px 10px 0px transparent;
    }
    .icon-content{
        width: 50%;
        background-color: aquamarine;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .img-icon{
        width: 30%;
        height: auto;
    }
    .text-container-section{
        float: none;
        margin: auto;
        
      }
      .describe{
        width: 100%;
        line-height: 1.1em;
        margin-top: 0px;
      }
      .topic{
        padding-bottom: 20px;
      }
    .details{
        color: #fff;
    }
    .second-table{
        display: flex;
         flex-wrap: wrap;
        flex-direction: row;
      //  background-color: red;
    }
    .first-table{
        //vertical-align: initial;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .speaker-item{
        display: flex;
    }
    .agenda-text{
        font-family: 'Times New Roman', Times, serif;
        font-size: 26px;
        font-weight: 500;
        width: 40%;
        color: #fff;
        text-align: center;
    }
    .title-text{
        color: #fff;
        padding-left: 20px;
        padding-block: 5px;
        width: 50%;
        text-align: left;
        line-break:strict;
        
    }
    .second-content{
      background-color: #000;
    }
    .img-sustain{
        display: block;
        width: 100%;
        // height: 150%;
        height: 50vh;
        padding: 5px;

    }
    .date-text{
        font-weight: 500;
        font-size: 16px;
        color: #fff;
    }
    .topic-text{
        color: #fff;
    }
    .speaker-text{
        width: 100%;
    }
    .table-content{
        background-color: #575556;
        display: block;
       align-self: center;
       width: 80%;
    //    padding-inline: 16px;
       margin-block: 45px;
    }
    .popup-content{
        background-color: rgba(255, 255, 255, 0.8);
        width: 32%;
        height: 50%;
        display: block;
        padding-top: 25px;
        padding-inline: 20px;
        margin-left: 40%;
        margin-top: 7%;
        font-size: 16px;
        color: #4d4d4d;
        font-weight: 400;
        line-height: 27px;
        // margin: 0 auto;
    }
    .pop-details{
        font-size: 10px;
        margin: 0px;
    }
    .title{
        font-size: 12px;
        margin-bottom: 10px;
        text-transform: capitalize;
        font-weight: 600;   
    }
    .content{
        margin-top: 20px;
    }
    .title-name{
        display: none;
    }
    .link-content{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding-inline: 40%;
        margin-block: 20px;
       
    }
    @media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
        .practise-tile {
            // height: 130vh
        }
    }
    @media only screen and (max-width: 600px) {
        .head{
            display:none ;
        }
        .agenda-text{
            display:none ;
        }
        .speaker-item{
            display: block;
        }
        .title-name{
            font-size: 25px;
        font-weight: 600;
        color: #fff;
        display: block;
        text-align: center;
        }
        .popup-content{
            width: 100%;
        }
        .img-sustain {
            height: 100%;
        }
        .table-content{
            width: 100%;
        }  
        .first-table{
            display: grid;
            width: 100%;
        }
        .date-text{
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 20px;
            
        }
        .topic-text{
            font-size: 15px;
            font-weight: 600;
        }
        li{
            //  width: 300px;
              font-size: 14px;
              font-weight: 600;
          }
           td{
             padding-left: 10px;
             color: #fff;
            
         }
    }
}