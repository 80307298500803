@import "../../common/scss/variables";
.privacy-container{
    display: inline;
    justify-content: center;
    .privacy-header{
        height: 10vh;
        width: 100%;
        background-color: black ;
    }
    .privacy-title{
        color: #fff;
        font-size: 35px;
        text-align: center;
        font-family: $MBCorpoATitleRegular;
        font-weight: 600;
        letter-spacing: 0.03em;
    }
    .press-text {
        // height: 80vh;
        // width: 100%;
        position: absolute;
      margin-top: 50px;
        display: flex;
        font-size: 37px;
        left: 20%;
        color: #fff;
        letter-spacing:0;
       
        
    }
    .press {
        height: 100%;
       
    }
  
    .text-container-section{
        float: none;
        margin: auto;
    }
    .about-title{
        font-size: 34px;
        letter-spacing: 0.01em;
        font-weight: 500;
        margin-left: 15px;
    }
    .img-content{
        width: $full-margin-width;
        height: $full-margin-width;
       // display: inline-block;
        background-color: black;
        padding-bottom: 5%;
      }
    .img-content{
        display: flex;
        background-color: #fff;
    }
    .para-content {
        margin: 20px;
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
    }
    .address-content {
        margin-left: 20px;
        font-size: 14px;
        font-family: inherit;
        font-weight: 500;
        margin-bottom: 0px;
    }
    .first-content, .second-content {
        margin: auto;
        width: 80%;
    }
    @media screen and (min-device-width: 768px)
        and (max-device-width: 1024px) {
            .press-text{
                left: 15%;
            }
        }
}