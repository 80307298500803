
@import "../../common/scss/_variables.scss";
.footer{
    text-align: center;
    padding: 10px 10px 16px 0;
    background: $footer-color;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    display: flex;
   
    .footer-text{
        width:90%;
        margin: auto;
        height:5vh;
        margin-top: 10px;
    }
    @media only screen and (max-width: 600px) {
        .footer-text{
        margin-top: 10%;
        }
    }
}