.whatwedo-container{
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    .title-p{
        margin-bottom:20px;
    }
    .text-container-section{
        margin:22px auto;
        float:none
    }
.img-content{
    display: flex;
    background-color: #fff;
    width:100%
}
.para-content {
    margin: 20px
}
.about {
    margin-left: 20px;
}
.document_content {

align-items: center; 

}
.press-text {
    // height: 80vh;
    // width: 100%;
    position: absolute;
  
    display: flex;
    font-size: 37px;
    left: 15%;
    color: #fff;
   
   
}
.image {
    max-width:350px;
    //float: right;
    max-height: 200px;
    display: flex;
    flex-direction:column;
    align-items: right;
    padding-top: 25px;

}
.content  {
    //text-align: left;
    //margin:auto

}
.content p {
    font-size: 17px;
    color: #484646;
}
.paragraph {
   
    //margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
   // margin-left: -10px;
    //text-align: center;
}
.pcontent {
    font-family: "MB Corpo Text Regular";
    padding : 0px 0px 0px 0px;
    padding-top: 50px;
    padding-bottom: 30px;
    font-size: medium;

   margin-left:-10px
}
.custom-section {
   // margin-left:2px
   //right:15px;
}
.coloring {
    color:red
}
.divider {
    width:100%;
}
.divider hr {
    
    color : rgba(195,195,195,0.47);
    border-width: 1.6px;
    
}
.section {
    border-bottom: 0.01px solid #808080;
    display: flex;
    padding-bottom: 20px;
    flex-wrap: wrap;
    //box-sizing: border-box;
    
   // grid-template-columns: repeat(3, 1fr);
   // grid-template-rows : repeat(4, auto);
    justify-content: center;
    width: auto;
    //flex-direction: row;
    font-family: sans-serif;
}
.content {
    padding : 20px 0px 12px 3px;

}
.section .paragraph {
   // text-align: center;
}

p{
    font-size: 17px;
    color:#484646;
}
@media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
          .image {
            max-width:730px;
              //float: right;
           max-height: 600px;
           // max-width:730px;
            //float: right;
           // max-height: 600px;
          }
    }
    @media screen and (min-device-width: 820px)
    and (max-device-width: 1180px) {
          .image {
            max-width:780px;
              //float: right;
           max-height: 600px;
           // max-width:730px;
            //float: right;
           // max-height: 600px;
          }
    }

    @media only screen and (max-width: 600px) {
        .text-container-section{
            margin:20px auto;
            float:none
        }

        .title-content {
            font-size: 32px;;
        }
    }
}
 
