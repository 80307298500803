@import "../../common/scss/variables";
@import "../../styles/fonts";
.sustain-container {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  background-color: #000;
  //   align-items: center;
  justify-content: center;
  .speaker-name {
    color: #00dafb;
    text-align: left;
    margin-top: 50px;
    font-size: 18px;
    font-weight: bold;
    font-family: $MBCorpoATitleRegular;
  }
  .logo-sustain {
    position: absolute;
    height: 60%;
    width: 50%;
    top: 20%;
    left: 0%;
  }
  img {
    width: 100%;
  }
  table {
    width: 100%;
    // background-color: #575556;
  }
  thead {
    text-align: center;
    width: 100%;
    color: #fff;
    // align-items: center;
  }
  td {
    // padding-inline: 20px;
    color: #fff;
  }
  th {
    // padding-inline: 30px;
    align-items: center;
    color: #00dafb;
    width: 50%;
    text-align: left;
    font-size: 18px;
    font-family: $MBCorpoATitleRegular;
  }
  tr {
    border-bottom: 1px solid transparent;
  }
  .contents {
    border-bottom: 1px solid #fff;
    margin-left: 0px;
    margin-right: 0px;
  }
  .row-content {
    border-bottom: 1px solid transparent;
  }
  li {
    //  width: 300px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin-left: 50px;
  }
  ul {
    padding: 10px;
  }
  .header-table {
    font-size: 17px;
    // padding-left: 50px;
    border-bottom: 1px solid transparent;
    height: 15vh;
  }
  .first-column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .second-column {
    padding: 10px;
    padding-top: 5rem;
  }
  .parent-item {
    display: flex;
    flex-direction: row;
  }
  display: flex;
  .img-modal {
    min-width: auto;
    height: auto;
    display: block;
    pointer-events: none;
    visibility: visible;
    opacity: 1;
  }
  .time-text {
    color: #00dafb;
    font-size: 15px;
    font-weight: 600;
    padding: 20px;
    font-family: $MBCorpoSText;
  }
  .ehs-p1 {
    top: 5%;
    font-size: 17px;
    font-family: $MBCorpoTextRegular;
    font-weight: 500;
    letter-spacing: 0.005em;
  }
  .ehs-p2 {
    top: 4%;
    font-size: 17px;
    font-family: $MBCorpoTextRegular;
    font-weight: 500;
    letter-spacing: 0.005em;
  }
  .layout {
    background-attachment: fixed;
  }
  .ehs-details {
    margin-top: 10px;
    position: absolute;
    left: 2%;
    width: 55%;
    top: 70%;
    padding: 12px;
  }
  .list-text {
    align-self: flex-end;
  }
  .head {
    display: flex;
    align-items: center;
    // justify-content: center;
    border-bottom: 1px solid white;
  }
  .second-border {
    border-bottom: 1px solid transparent;
    width: 100%;
  }
  .table-item {
    flex-direction: column;
  }
  .img-content {
    width: 100%;
  }
  .box-shadow {
    box-shadow: 0px 0px 10px 0px transparent;
  }
  .icon-content {
    width: 50%;
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .img-icon {
    width: 30%;
    height: auto;
  }
  .text-container-section {
    float: none;
    margin: auto;
  }
  .describe {
    width: 100%;
    line-height: 1.1em;
    margin-top: 0px;
    color: #fff;
  }
  .details {
    color: #fff;
  }
  .second-table {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    //  background-color: red;
  }
  .first-table {
    //vertical-align: initial;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .speaker-item {
    display: flex;
  }
  .agenda-text {
    letter-spacing: 0.01em;
    font-family: "Times New Roman", Sans-serif;
    font-size: 24px;
    font-weight: 500;
    width: 50%;
    text-align: center;
    color: #fff;
  }
  .agenda-text-24 {
    letter-spacing: 0.01em;
    font-family: $MBCorpoSTitle;
    font-size: 24px;
    font-weight: 500;
    width: 50%;
    text-align: center;
    color: #fff;
  }
  .modal-contents {
    font-size: 16px;
    color: #fff;
  }
  .title-text {
    color: #fff;
    padding-left: 20px;
    padding-block: 5px;
    width: 50%;
    text-align: left;
    line-break: strict;
  }
  .second-content {
    background-color: #000;
  }
  .img-sustain {
    display: block;
    width: 100%;
    // height: 150%;
    height: 50vh;
    padding: 5px;
    margin-block: 20px;
  }

  .date-text {
    padding-top: 5px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
  }
  .date-text-content {
    padding-top: 5px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-align: center;
  }
  .topic-text {
    color: #fff;
  }
  .speaker-text {
    width: 100%;
  }
  .img-arrow {
    width: 5%;
    margin-left: 5px;
  }
  .table-content {
    background-color: #514c4d;
    display: block;
    align-self: center;
    //    padding-inline: 16px;
    margin-inline: 10%;
    margin-block: 30px;
    padding-inline: 30px;
    padding-top: 20px;
    padding-bottom: 30px;
    position: relative;
  }
  .table-mac {
    width: 61%;
  }
  .profile-desc {
    margin: 5px 0px;
  }

  .modal-view {
    display: flex;
    flex-direction: row;
  }
  .popup-content {
    background-color: rgba(255, 255, 255, 0.8);
    width: 32%;
    // height: 50%;
    padding-bottom: 25px;
    display: block;
    padding-top: 20px;
    padding-inline: 20px;
    margin-left: 35%;
    margin-top: 3%;
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 27px;
  }
  .pop-details {
    font-size: 13px;
    margin: 0px;
  }
  .title {
    font-size: 15px;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-weight: 600;
  }
  .content {
    margin-top: 20px;
  }
  .title-name {
    display: none;
  }
  .img-sustain-mobile {
    display: none;
  }
  .parent-link {
    // text-align: center;
  }
  .link-content {
    //   display: flex;
    // flex-direction: column;
    // align-items: center;
    //    justify-content: center;
    text-align: right;
    //  margin-inline: 20px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    // padding-top: 1.5rem;
    padding-left: 32px;
    padding-top: 8px;
  }

  .register-now-btn {
    display: inline-flex;
    padding: 0.813rem 1.625rem;
    // margin-top: 3%;
    // margin-bottom: 4%;
    border: none;
    justify-content: center;
    align-items: center;
    // gap: 0.625rem;
    border-radius: 0.125rem;
    background: #0078d6;
    color: #fff;
    font-family: $MBCorpoSText;
    font-size: 16px;
    font-style: normal;
    // line-height: normal;
    cursor: pointer;
    border-radius: 5px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .parent-link {
      text-align: center;
    }
    .link-content {
      text-align: center;
    }
    .practise-tile {
      // height: 130vh
    }
    .img-sustain {
      display: flex;
      width: 100%;
    }
    .img-sustain-mobile {
      // display: inline;
      //   width: 100%;
      // // height: 150%;
      // height: 50vh;
      // padding: 5px;
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      // padding-top: 1.5rem;
      padding-left: 36px;
    }
    .register-now-btn {
      display: inline-flex;
      padding: 0.813rem 1.625rem;
      // margin-top: 3%;
      margin-bottom: 4%;
      border: none;
      justify-content: center;
      align-items: center;
      // gap: 0.625rem;
      border-radius: 0.125rem;
      background: #0078d6;
      color: #fff;
      font-family: $MBCorpoSText;
      font-size: 10px;
      font-style: normal;
      line-height: normal;
      cursor: pointer;
      border-radius: 5px;
      padding: 4px;
      /* margin-left: -21px; */
      position: relative;
      left: -20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .ehs-details {
      display: none;
    }
    .modal-contents {
      font-size: 11px;
    }
    .head {
      display: none;
    }
    .parent-link {
      text-align: center;
    }
    .link-content {
      text-align: center;
    }
    .modal-view {
      // width: 80%;
      padding-top: 20%;
      height: auto;
      display: block;
      // flex-direction: row;
    }
    .img-sustain {
      display: none;
    }

    .img-sustain-mobile {
      display: block;
      width: 100%;
      height: 50vh;
      padding: 5px;
    }
    .header-table {
      display: none;
    }
    .agenda-text {
      display: none;
    }
    .speaker-item {
      display: block;
    }
    .title-name {
      font-size: 19px;
      font-weight: 600;
      color: #fff;
      display: block;
      text-align: center;
      margin-inline: 20px;
    }
    .popup-content {
      width: 100%;
      margin-left: 0%;
    }
    .img-sustain {
      height: 100%;
    }
    .table-content {
      margin-inline: 10px;
      // width: 100%;
    }
    .first-table {
      display: grid;
      width: 100%;
    }
    .date-text {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .topic-text {
      font-size: 15px;
      font-weight: 600;
    }
    li {
      //  width: 300px;
      font-size: 14px;
      font-weight: 600;
    }
    td {
      padding-left: 10px;
      color: #fff;
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      // padding-top: 1.5rem;
      padding-left: 28px;
    }
    .register-now-btn {
      display: inline-flex;
      padding: 0.813rem 1.625rem;
      // margin-top: 3%;
      margin-bottom: 4%;
      border: none;
      justify-content: center;
      align-items: center;
      // gap: 0.625rem;
      border-radius: 0.125rem;
      background: #0078d6;
      color: #fff;
      font-family: $MBCorpoSText;
      font-size: 10px;
      font-style: normal;
      line-height: normal;
      cursor: pointer;
      border-radius: 5px;
      padding: 4px;
      /* margin-left: -21px; */
      position: relative;
      left: -20px;
    }
  }
}
.sustainability-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.sustainability-wrapper {
  display: flex;
  color: #fff;
  font-size: 16px;
  font-family: $MBCorpoSText;
  font-weight: 500;
  padding: 20px;
}
.time-coulmn {
  width: 30%;
  text-align: center;
}
.session-column {
  width: 70%;
}
.topic-coulmn {
  width: 55%;
  padding-left: 3.125rem;
}
.time-coulmn-data {
  width: 30%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.session-column-data {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  width: 70%;
  padding-left: 4%;
}
.topic-coulmn-data {
  width: 55%;
  padding-left: 10%;
}
.lunch-session {
  padding-left: 15%;
}
