
.header{
 
}
.site-header-inner{
 
  //padding-left: 150px;

  img.logo{
    max-width:190px;
    width: 47px;
    
    margin-top: 4px;

  }

  .logo-container{
    height:49px;
  }
 
  .primary-link{
    line-height:75px;
    
    font-family: MS Sans Serif,Geneva,sans-serif;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .1px;
    text-decoration: none
  }

  .menu-toggle-container{
    a{
      color:#13c0f4 ;
      padding-inline: 5px;
    }
    a:hover{
     color:#13c0f4;
    }
    text-align: right;
  }

  .logo-link:hover{
    opacity:1px;
    opacity: 0.5;
    cursor: pointer;
    transition: all .3s ease;
  }
  
}
.toggle-first{
  line-height:75px;
    
  font-family: MS Sans Serif,Geneva,sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: .1px;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.mobile-links{
  display:none
}

.mobile-links{
  position: absolute;
    //top: 95px;
    left: 0px;
    z-index: 999;
    background: #fff;
    width: 100%;
}

.desktop-menu{
  display:block;
  .logo-container{
    background: #000;
  }
}
.mobile-menu{
  display:none;
 
 
}
.mobile-content{
  background: #000;
  padding: 0px 0px;
}
.dektop-menu-list{
  background: rgba(0,0,0,1);
}


.logo-container{
  text-align:center;
}
@media only screen and (max-width: 950px) {
  .desktop-menu{
    display:none;
  }
  .mobile-menu{
    display:block;
  }

  .site-header-inner img.logo {
    margin-top: -1px;;
  }
  .logo-container {
    text-align: left;
  
  }
  .site-header-inner{
    padding-left: 0px;
  
  } 
  
}

@media screen and (min-device-width: 768px)
    and (max-device-width: 1024) {
   
      .desktop-menu{
        display:none;
      }
      .mobile-menu{
        display:block;
      }
      .logo-container {
        text-align: left;
      
      } 
      .site-header-inner{
        padding-left: 0px;
      } 
}




@media screen and (min-device-width: 951px)
    and (max-device-width: 1120px) {
   
      .primary-link{
        font-size:10px !important;
      }
      .site-header-inner{
        padding-left: 0px;
      } 
}