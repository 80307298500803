
 .contacts-image{
    width: 100%;
  }
  .box-shadow {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
    padding:1px 10px;
    padding: 11px 1px;
}
@media only screen and (max-width: 600px) {
    .second-column{
        margin-top:20px;
    }
  
  }
  @media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
    .contacts-image{
       width: fit-content
      }
  }
 

