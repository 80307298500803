// @import "../../common/scss/variables";
// @import "../../styles/fonts";

@import "../../common/scss/variables";

header {
  overflow: hidden;
  background: #000000;
}

.banner {
  position: relative;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.sustainability-garage-banner-title {
  position: absolute;
  width: 100%;
  top: 15%;

  h1 {
    color: #ffffff;
    // font-family: "MBCorpoARegular";
    font-size: 36px;
    line-height: 1.1;
    margin-top: 0px;
    margin-left: 10px;
  }
}

// .banner-title h1 {
//   color: #ffffff;
//   // font-family: "MBCorpoARegular";
//   font-size: 36px;
//   line-height: 1.1;
// }

.sustainability-garage-section {
  padding: 40px 0px;
}

.sustainability-header-container{
  max-width: 73%;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: 18%;
}

.section-container{
  max-width: 73%;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}



p {
  font-size: 15px;
  line-height: 1.6;
  color: var(--gray);
  // font-family: "MBCorpoSRegular";
}

p:last-child,
p:only-child {
  margin-bottom: 0;
}

.mercedes-garage {
  padding: 90px 0 180px 0;

  p {
    color: #ffffff;
  }
}



.sustainability-section-title {
  color: #000000;
  font-size: 34px;
  line-height: 1.2;
  padding-bottom: 40px;
  text-align: center;
  font-family: $MBCorpoATitleRegular;
  margin-bottom: 0;
}

.mercedes-garage .sustainability-section-title {
  color: #ffffff;
  text-align: left;
  padding-bottom: 20px;
}

.icon-block img {
  margin-bottom: 15px;
  max-width: 100px;
}

.sustainability-garage-icon-container{

  img{
    width: 398px;
    margin-bottom: 15px;
  }
}

// .mercedes-garage p {
//   color: #ffffff;
// }

.card-singal {
  border-radius: 18px;
  position: relative;
  overflow: hidden;
  transition: 0.5s ease;
}

.card-singal img {
  width: 100%;
  object-fit: cover;
}

.card-list {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  text-align: center;
  transition: 0.5s ease;
  padding: 50px 30px 30px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: unset;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  h3{
    font-family:  $MBCorpoTextRegular;
  }

}

.card-singal:hover .card-list {
  bottom: 0;
  height: 100%;
  justify-content: center;
}

.card-list h3 {
  color: #3cb6e9;
  font-size: 28px;
  line-height: 1.3;
  padding-bottom: 0;
  // font-family: "MBCorpoSRegular";
  text-transform: uppercase;
  padding-bottom: 30px;
  transition: 0.5s ease;
  margin-bottom: 0;
}

.card-list p {
  color: #ffffff;
  // font-family: "MBCorpoSRegular";
  opacity: 0;
  /* min-height: 150px; */
}

.card-singal:hover .card-list p {
  opacity: 1;
  transition: 0.5s ease;
}

.appcontent .sustainability-section-title {
  text-align: left;
  padding-bottom: 20px;
}

.appcontent p {
  margin-bottom: 0;
  color: #000000;
}

.calling-app {
  padding-top: 80px;
  padding-bottom: 80px;
}

.primaryBtn {
  background: #000000;
  color: #ffffff;
  font-size: 15px;
  line-height: 1;
  // font-family: "MBCorpoSRegular";
  text-transform: uppercase;
  padding: 15px 20px;
  display: inline-block;
  // text-decoration: none;
  // cursor: pointer;
  text-align: center;
  /* margin-top: 30px; */
  transition: 0.2s ease;
}
.btnGroup {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 6px;
  a{
    font-family:  $MBCorpoTextRegular;
  }
}
.btnGroup p {
  width: 165px;
  font-size: 16px;
  text-transform: uppercase;
}

.btnGroup:only-child,
.btnGroup:first-of-type {
  margin-top: 20px;
}

.primaryBtn:hover {
  transition: 0.2s ease;
  color: #ffffff;
  background: #08c8e5;
  text-decoration: none;
}

.mediaslider {
  padding-bottom: 250px;
  position: relative;
}

// .mediaslider img {
//   width: 100%;
//   object-fit: fill;
// }

.mediaslider .sustainability-section-title {
  color: #ffffff;
}

// .sliderElement {
//   background: #ffffff;
//   padding: 10px 15px;
//   margin-bottom: 40px;
// }

// .sliderElement .carousel {
//   padding: 0;
// }

// .sliderElement .carousel-control-next,
// .sliderElement .carousel-control-prev {
//   width: 40px;
//   opacity: 0.8;
// }

// .sliderElement .carousel-control-prev {
//   left: -56px;
// }

// .sliderElement .carousel-control-next {
//   right: -56px;
// }

// .sliderElement .carousel-indicators {
//   position: absolute;
//   padding: 0;
//   margin: 0;
//   bottom: -40px;
// }

// .sliderElement .carousel-indicators [data-bs-target] {
//   width: 9px;
//   height: 9px;
//   border-radius: 9px;
//   background: transparent;
//   border: 1px solid #ffffff;
// }

// .sliderElement .carousel-indicators .active {
//   opacity: 1;
//   background: #ffffff;
// }

// .ContentBox {
//   padding: 20px 15px;
// }

// .ContentBox p {
//   color: #000000;
//   font-size: 28px;
//   line-height: 1.3;
//   // font-family: "MBCorpoARegular";
//   max-width: 80%;
// }

// .ContentBox label,
// .ContentBox label a {
//   color: #08c8e5;
//   font-size: 18px;
//   line-height: 1;
//   // font-family: "MBCorpoSRegular";
// }

.BtnWrap {
  text-align: right;
}

.button {
  color: #ffffff;
  font-size: 15px;
  line-height: 1;
  // font-family: "MBCorpoSRegular";
  background: #08c8e5;
  text-decoration: none;
  padding: 15px 25px;
  display: inline-block;
  border: 2px solid #08c8e5;
  transition: 0.2s ease;
}

.button:hover {
  color: #08c8e5;
  background: #ffffff;
  transition: 0.2s ease;
}

.footer {
  background: #0a0a0a;
  color: #fff;
  display: flex;
  font-size: 12px;
  line-height: 1;
  padding: 20px 10px 30px 0;
  text-align: center;
}

.footer a {
  color: #08c8e5;
  text-decoration: none;
}

.media-slider {
  padding-left: 8px;
  padding-right: 8px;
}
.media-slider .control-dots {
  position: relative;
}
/* Device Style */

@media screen and (max-width: 1536px) {
  .card-list h3 {
    font-size: 24px;
  }

  .btnGroup {
    margin-top: 10px;
  }

  .btnGroup:only-child,
  .btnGroup:first-of-type {
    margin-top: 15px;
  }

  // .ContentBox p {
  //   font-size: 24px;
  // }

  .button {
    padding: 13px 20px;
  }
}

@media screen and (max-width: 1366px) {
  // .ContentBox p {
  //   font-size: 22px;
  // }

  .button {
    padding: 10px 18px;
  }

  // .ContentBox label {
  //   font-size: 16px;
  // }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 32px;
  }

  .card-list {
    /* top: 62%; */
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 32%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .card-list h3 {
    font-size: 22px;
    padding-bottom: 20px;
  }

  .card-list {
    padding: 15px 15px;
  }

  // .ContentBox p {
  //   font-size: 20px;
  // }

  .icon-block img {
    max-width: 70px;
  }
  .card-singal {
    min-height: 450px;
  }
}

@media screen and (max-width: 767px) {
  .card-list {
    justify-content: center;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 32%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .card-list p {
    opacity: 1;
    min-height: auto;
  }

  .card-singal {
    max-height: 400px;
    min-height: 450px;
  }

  .section {
    padding: 30px 20px;
  }

  .title {
    padding-bottom: 30px;
  }

  .media-slider {
    padding-left: 0;
    padding-right: 0;
    li {
      background-color: #fff;
    }
    .carousel .slide {
      background-color: #ffff;
    }
  }

  .section-container{
    max-width: 85%;
  }

  .sustainability-header-container{
    margin-left: 3%;
  }

  .sustainability-garage-icon-container{

    img{
      width: 324px;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 675px) {
  .title {
    font-size: 28px;
  }

  .card-list h3 {
    font-size: 20px;
    padding-bottom: 15px;
    line-height: 1.1;
  }

  // .ContentBox p {
  //   max-width: 100%;
  // }

  // .ContentBox p {
  //   font-size: 18px;
  // }

  // .ContentBox {
  //   padding: 15px 0px 10px;
  // }
}

@media screen and (max-width: 575px) {
  .mediaslider {
    padding-left: 29px;
    padding-right: 29px;
  }

  // .sliderElement .carousel-control-next,
  // .sliderElement .carousel-control-prev {
  //   width: 24px;
  // }

  .primaryBtn {
    padding: 15px 10px;
  }

  .d-Block {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .banner-title h1 {
    font-size: 28px;
  }

  .title {
    font-size: 24px;
  }

  .mediaslider {
    padding-left: 29px;
    padding-right: 29px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .media-slider {
    padding-right: 68px;
    padding-left: 26px;

    .carousel .slide {
      background-color: #ffff;
    }
  }
  // .sustainability-header-container{
  //   margin-right: 13%;
  // }
}
