@import "../../common/scss/_variables.scss";
.pwd-container{

  .banner-parent-conetnt{
    .banner-title1, .banner-title2{
      padding:0;
      margin:0;
      font-size: 35px;
      font-weight: 300;
      letter-spacing: 0.8px;

    }
    .banner-title1{
      color: #0BC9FF;
    }
    .banner-title2{
      color: #fff;
    }
    img{
      width: 100%;
    }
   
    position: relative;
    .banner-content{
      position: absolute;
      top: 45px;
      left: 21%;
      width: 45%;
    }

    .banner-title{
      font-size: 37px;
    }

    .hiring-title{
      font-size: 63px;
      margin-top:36px;
    }
    @media only screen and (max-width: 600px) {
      .banner-image{
        height: 250px;
      }
      .banner-content{
        position: absolute;
        top: 30px;
        left: 2%;
        width: 100%;
       
      }
    }

    @media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
      .banner-image{
        height: 250px;
      }
      .banner-content{
        position: absolute;
        top: 30px;
        left: 2%;
        width: 70%;
      
      
      }
    }
   
  }
 
  .pwd-banner img{
    width:100%
  }
 .comon-blue-button{
  padding: 10px 20px;
  background-color: #29B1ee;
  border: none;
  border-radius: 4px;
  color: #fff;

 }
 .first-button-content,.second-button-content{
  margin:20px 0px;
 }
  .pwd-center-container{
    float: none;
    margin: auto;
    .first-section{
     .first-p{
        margin:30px 0px 20px 0px;
      }
      .second-p{
        margin:10px 0px 30px 0px;;
      }
        p{
          font-family:  $DaimlerCSProRegular !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.4em;
          color:#101010;
     }
    }

    .second-section p,.second-section ul,.pwds-steps{
     
      color: #101010;
    
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4em;
    }
    h2{
      font-size: 34px;
      margin:25px 0px;
    }
   
  .opp-section-div{
    margin:auto;  
    float: none
  }

  ul.links1,.subLinks{
    font-size: 17px;
    line-height: 1.6em;
    letter-spacing: 0.2px;
    padding: 15px;
    color:#000000;
  }
  .toggle-icon{
    position: absolute;
    right: 0;
    height: 100%;
    width: 60px;
    cursor: pointer;
    color: #555;
    font-size: 18px;
    text-align: center;
  }

  .accordian{
    .sub-text{
    
    
      color: #2E2D2D;
      font-family:  $DaimlerCSProRegular !important;
    }
    ul{
      list-style-type: none;
    }
    
    .links1 
        { 
          li.questions-li:last-child{
           border-bottom: 4px solid #F8F8F8 !important;
        }

        padding:0;
    }
    ul.links1{
    
      li.questions-li{
      
        border: 4px solid #F8F8F8;
        /* border-width: 4px; */
        position: relative;
        border-bottom: 0px solid #F8F8F8;
        .toggle-row{
          padding:15px 4px;
          cursor: pointer;
          p.question-title{
            line-height: 1.2;
            color:#000000 !important;
            font-size:18px;

          }
        }
        
      }

     
    }

    span.toggle-questions{
      position: absolute;
      right: 0;
    
      height: 100%;
      width: 60px;
      cursor: pointer;
      color: #555;
      font-size:18px;
      text-align: center;
  }

  .subLinks {
    margin:0;
    padding:0;
    li.subLinksLi{
      border-top: 4px solid #F8F8F8;
      padding: 20px 15px;
    }

    .guidelines-ul,.assessment-ul{
      margin: 0;
      list-style-type: disc;
       padding: 1px 0px 10px 20px;
    }

    .assessment-ul{
      list-style-type: initial;
    }
   }

  
  }

  .job-link{
    float: none;
    margin: auto;
    /* margin-top: 20px; */
    padding: 50px 15px;
  }

  .job-link-text{
    color:#fff;
    font-size: 20px;
  }
 
  .lob-link-clickhere a{
    color:#fff;
    cursor: pointer;
  }

  .job-application-section {
    float:none;
    margin: auto
  }

  }

  .email-text-content{}
}