.benifits-container{
  display: flex;
  flex-wrap: wrap;
  img{
    width: 100%;
  }
.benifits-image{
  width:100%;
}
.address{
  color: #000000;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 0.3px;
}
.press-text {
  // height: 80vh;
  // width: 100%;

  position: absolute;
  
  display: flex;
  font-size: 37px;
  left: 18%;
 // font-weight: 600;
  color:#fff;
  letter-spacing:0;
  
}
.text-container-section{
  float: none;
  margin: auto;
  img{
      margin-bottom:20px;
      width:100%
  }

  h2{
      margin-top: 36px;
      margin-bottom: 20px;
  }
  li{

  }
  p{
      // font-size:15px;
      margin: 0 0 20px;
  }
}

  .first-box{
    margin-top: 38px;
  }
  @media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
        .innovation-content {
            padding-left: 15vh;
        }
    }
    @media only screen and (max-width: 600px) {
      .press-text {
       
      }
    }
}


.test{
  float: none;
    margin: auto;
}