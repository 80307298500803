
@font-face {
    font-family: "MB Corpo Text Regular";
    src: url("../../common/fonts/MBCorpo-Text-Regular.ttf") format("truetype");
    // src: url("./fonts/DaimlerCS-Demi.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "MB Corpo A Title Regular";
    // src: url("./fonts/DaimlerCEPro-Regular.otf") format("truetype");
    src: url("../../common/fonts/MBCorpoATitleCond-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "daimler-font-title";
    // src: url("./fonts/DaimlerCEPro-Regular.otf") format("truetype");
    src: url("../../common/fonts/DaimlerCS-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "DaimlerCSPro-regular";
    // src: url("./fonts/DaimlerCEPro-Regular.otf") format("truetype");
    src: url("../../common/fonts/DaimlerCSPro-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "DaimlerCS-Regular";
    // src: url("./fonts/DaimlerCEPro-Regular.otf") format("truetype");
    src: url("../../common/fonts/DaimlerCS-Regular.ttf") format("truetype");
  }
  $MBCorpoATitleRegular:"MB Corpo A Title Regular" ;
  $DaimlerFontTitle:"daimler-font-title" ;
  $MBCorpoTextRegular:"MB Corpo Text Regular" ; 
  $DaimlerCSProRegular:"DaimlerCSPro-regular" ; 
  $DaimlerCSRegular:"DaimlerCS-Regular" ; 
$small-margin-width:50%;

$common-backgrond-black:#0a0a0a;
$black:#000;
$auto-margin-width:64%;
$footer-color: $common-backgrond-black;
$hover-color:#23bedd;
$medium-margin-width:70%;
$margin-width-eight:64%;
$full-margin-width:100%;

