.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
  }
  
  .modal-main {
    position:fixed;
    width: 100%;
    height: 100%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 5px;
    z-index: 1000;
    overflow: auto;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }