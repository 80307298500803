@import "../../../src/common/scss//variables";

.media-slider-container {
  background-color: #ffff;


}


.slider-wrapper {
  display: flex;
}
.slider-image {
  object-fit: fill;
  height: 200px;
  padding: 6px;
}

.data-text {
  color: black;
}

.header-label-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4%;
  margin-top: 4%;
  width: 100%;
  text-align: left;
}

.title-text {
  font-family: $MBCorpoATitleRegular;
  font-size: 22px;
}

.subtitle-text {
  font-family: $MBCorpoTextRegular;
  font-size: 16px;
}

.read-more-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 36px;
}

.prev-btn {
  position: absolute;
  left: 12%;
  top: 36%;
  background: transparent;
  outline: none;
  border: none;
}

.next-btn {
  position: absolute;
  top: 37%;
  right: 12%;
  background: transparent;
  outline: none;
  border: none;
}

.image-header-container {
  width: 40%;
}

@media only screen and (max-width: 768px) {


  .slider-wrapper {
    display: flex;
    flex-direction: column;
  }

  .slider-image {
    width: 100%;
  }

  .next-btn {
    position: absolute;
    top: 40%;
    right: 3%;
    background: transparent;
    outline: none;
    border: none;
  }

  .prev-btn {
    position: absolute;
    left: 3%;
    top: 40%;
    background: transparent;
    outline: none;
    border: none;
  }

  .title-text {
    font-family: $MBCorpoATitleRegular;
    font-size: 18px;
    text-align: left;
  }

  .header-label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 10px;
    margin-top: 0;
    margin-left: 0;
  }

  .read-more-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 36px;
    padding-bottom: 12px;
  }

  .image-header-container {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .next-btn {
    position: absolute;
    right: 16%;
    background: transparent;
    outline: none;
    border: none;
  }

  .prev-btn {
    position: absolute;
    left: 12%;
    top: 38%;
    background: transparent;
    outline: none;
    border: none;
  }
}
