
@import "../../common/scss/variables";
  h1 {
    font-family:$MBCorpoATitleRegular;
  
    color: #000000;
    font-size: 31px;
    font-weight: 500;
   // letter-spacing: 1.5px;

 
  }

  h2{
    color: #000000;
    font-family: $MBCorpoATitleRegular;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0.2px;
  }
  h3{
    font-family:  $DaimlerFontTitle ;
  }
  h4{
   // font-family: $MBCorpoTextRegular;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 1.6em;
  
   
  }
 h5{
    font-family:$MBCorpoATitleRegular;
    color: #ffffff;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  p{
    font-family: $MBCorpoTextRegular;
    font-size: 15px;
    color: #161313;
    // color: #000000;
    font-weight: 500;
    line-height: 1.6em;
  
   
  }
  a{
    color: #00ccff;
  }
  .consent-link{
    color:  rgb(0, 119, 155);
 }
 .cookie-consent{
     display: block;
     
 }
 
 .consent-details{
     font-family: inherit;
     font-weight: 400;
     font-size: 14px;
 }
 .cookie-title{
     font-size: 18px;
     padding: 0 0 1.5rem 0;
     line-height: 1.5;
     font-weight: bolder;
     font-family: inherit;
 }
 #rcc-confirm-button{
     background: #000 !important;
     color: #fff !important;
     font-size: 18px !important;
     font-weight: 500 !important;
     margin-right: 2px !important;
 }
 #rcc-decline-button{
     background: #000 !important;
     color:  #fff !important;
     font-size: 18px !important;
     font-weight: 500 !important;
     margin-left: 2px !important;
 }
 
  
  // p1{
  //   font-size: 16px;
  //   // font-family: $MBCorpoTextRegular;
  //   // font-family: "mbrdi_font_text" !important;
  // }
  .title-content{
    color: #000000;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0.3px;
  }
  .common-button{
    font-weight: 500;
    font-size: 12px;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    background-color: #08cae7;
    border-radius: 0px 0px 0px 0px;
    padding: 5px 20px;
    border:none
  }
  .button-container{
    background-color: transparent;
    border-width: 0px;
    position: absolute;
    top:0px;
    right:5px;
    margin:10px;
  }
  .box-shadow{
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14);
  }
  .layout {
    background-attachment: fixed;  

    @media only screen and (max-width: 600px) {
      .kaIiPz{
        left: 90% !important;
        bottom: 60px !important;
      }

     
    }
  }
 
  @media only screen and (max-width: 600px) {
  
   
  }

  .row {
     margin-right: 0px; 
     margin-left: 0px; 
}

@media only screen and (max-width: 600px) {
  
   .CookieConsent{
    left: 58px !important;
    position: fixed;
    width: 215px !important;
    z-index: 999 !important;
    top: 30% !important;
   }
}
