
.ehs-p1 {
    // height: 80vh;
    // width: 100%;
    font-size: 17px;

    color: #fff; 
    font-weight: bold;
    // font-family: "MBCorpo Text-Regular", Sans-serif;
}
.ehs-p2 {
    // height: 80vh;
    // width: 100%;
 
    font-size: 17px;
    font-weight: bold;
    color: #fff; 
    // font-family: "MBCorpo Text-Regular", Sans-serif;
}
.ehs-text {
    // height: 80vh;
    // width: 100%;
    position: absolute;
    font-weight: 600;
    display: flex;
    font-size: 30px;
    left: 15%;
    top:32%;
    color: #fff;
}
.know-more-button-content{
    margin-top: 10px;
}
.ehs-note {
    margin-right: 29%;
    font-style: italic;
    color: #000000;
    font-weight: 900px;
}
.ehs{
    height: 80vh;
    width: fit-content;
    // background-color: transparent;
}
.ehs-head {
    //  padding-left: 620px;
    display: flex;
    flex-wrap: wrap;
}
.ehs-content {
    background-color: #fff;
    // height: 50%;
    // width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
 .box-shadow {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            padding:1px 10px;
            padding: 11px 1px;
        }
.ehs-img {
    height: 50px;
    width: 50px;
    margin: 20px;
}
.ehs-details {
    margin-top: 10px;
    position: absolute;
    left: 14%;
    width: 80%;
    top: 40%;
    padding: 12px;
}
.ehs-container{
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
     width: 100%;
     .ehs-footer{
        font-size: 17px;
      }
    @media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
        .ehs-details{
            top: 29%;
        }

        .ehs-text{
            top:17%;
        }
        .ehs-card {
            padding: 20px;
            // height:70vh;
            width: 100%; 
            margin-right: 40px;
            // display: block;
            // flex-wrap: wrap;
            
        }
        .ehs-p1 {
            top: 5%;
            font-size: 15px;
        }
        .ehs-p2 {
            top: 4%;
            font-size: 15px;
        }
        .ehs-head {
            height: 98vh;
        }
        .ehs-note {
            margin: 10%;
        }
        .ehs-box {
            height:fit-content;
            // width: 100%;
        }
        
     }
     @media only screen and (max-width: 600px) {
        .ehs-footer{
            font-size: 15px;
        }
        .ehs-card {
            padding: 10px;
          
            width: 45vh; 
            // margin-right: 40px;
            display: flex;
            flex-wrap: wrap;
        }
        .ehs-text{
            left:4%;
            top: 12%;
        }   
        .ehs-details {
            top:10%;
            left:2%;
            margin-top: 10px;
            position: relative;
            width: 96%;
            
        }
        .ehs{
            height: 120vh;
        }
        .ehs-head {
            //height: 80vh;
        }
        .ehs-box {
             display: inline;
            height: fit-content;
            // height:50vh;
            // width: 100%;
        }
        .ehs-p1 {
            font-size: 14px;
        }
        .ehs-p2 {
            font-size: 14px;
        }
        .ehs-note {
            margin-right: 0%;
           
        }
        .ehs-card {
         //   width: 100vh;
        }
        .ehs-content > div {
            // flex-basis: calc(90%);
          }
          .first-column{
            width: 150px;
          }
        
     }

.first-column{

    padding-top: 20px;
}
.ehs-col {
    background-color: red;
}
.ehs-box {
    height:20vh;
    // width: 60%;
}
.ehs-card {
    padding: 50px;
    // height:70vh;
}
.ehs-line{
    display: block;
    flex-wrap: wrap;
    height: 10px;
    width: 100%;
}
.ehs-tile {
    font-size: 22px;
}
.ehs-col {
    flex-direction: column;
}
.details {
        color: #000;
        font-size: 14px;
        letter-spacing: 0.2px;
        //height: 10px;
        display: flex;
         width: 100%;
}
.two-column-layout{
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .address{
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.2em;
      letter-spacing: 0.3px;
  }
  .text-container-section{
    float: none;
    margin: auto;
  }

  .box-contents{
    margin-bottom: 10px;
  }
.ehs-content > div {
    flex-basis: calc(70%);
  }
  .ehs-card > div {
    flex-basis: calc(100%);
  }
.footer-contents {
    display: flex;
    flex-wrap: wrap;
}
.footer-title {
    //margin-left: 20px;
}

.ehs-title-content{
    color: #fff;
    width: 70%;
    margin: auto;
    padding-top: 70px;

}

.ehs-section{
    padding:0px
}
}