.people-container{
    p{
      color: #383939;
    }
    display: inline;
    justify-content: center;
.practise-tile {
   display: flex;
   height:fit-content;

}

.press{
    position: relative;
  }
  .press-text {
    // height: 80vh;
    // width: 100%;
   // font-weight: 600;
    position: absolute;
  margin-top: 50px;
    display: flex;
    font-size: 37px;
    left: 20%;
    color: #fff;
    letter-spacing:0;
  } 
  .text-container-section{
    float: none;
    margin: auto;
  }
  .two-column-layout{
    margin-top: 30px;
    margin-bottom: 20px;
  }
.img-content{
    display: flex;
    background-color: #fff;
    width: 100%;
  }
.row-contents{
    overflow: hidden;
    width: 100%;
   height: 160px; 
  //   max-height: 35vh;
    -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
}
.address{
    color: #000000;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0.3px;
}
.first-column{
    padding-top: 20px;
}
.ehs-content {
    background-color: #fff;
    // height: 50%;
    // width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
@media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
    .practise-tile {
        // height: 130vh
    }
}
@media only screen and (max-width: 600px) {
    .practise-tile {
        display: block;
        height:fit-content;
     
     }
     .first-column{
        width: 150px;
      }
}
}