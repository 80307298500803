
@import "../../../common/scss/_variables.scss";
nav{
    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 70px;
   
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
  nav .navbar{
    height: 100%;
    max-width: 1250px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    /* background: red; */
   
  }
  .navbar .logo a{
    font-size: 30px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }
  nav .navbar .nav-links{
    line-height: 70px;
    height: 100%;
  }
  nav .navbar .links{
    display: flex;
  }
  nav .navbar .links li{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0 20px;
  }
  nav .navbar .links li a{
    height: 100%;
    text-decoration: none;
  
    color: #f4f4f4;
    font-family: MS Sans Serif,Geneva,sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: .1px;
  }
  .links li:hover .htmlcss-arrow,
  .links li:hover .js-arrow{
    transform: rotate(180deg);
    }
  nav .navbar .links li .arrow{
    /* background: red; */
    height: 100%;
    width: 22px;
    line-height: 70px;
    text-align: center;
    display: inline-block;
    color: #fff;
    transition: all 0.3s ease;
  }
  nav .navbar .links li .sub-menu{
    position: absolute;
    top: 69px;
    left: 0;
    line-height: 40px;
    background-color: rgba(0,0,0,.8);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 4px 4px;
    display: none;
    z-index: 2000;
    
  }

  nav .navbar .links li .arrow-img-hover{
      display: none;
  }
  nav .navbar .links li .sub-menu {
    padding-left:0;
}
  nav .navbar .links  li a:hover{
  color:$hover-color;
  cursor: pointer;
  }
  nav .navbar .links  li a:hover .arrow-img{
display: none;
  }
  nav .navbar .links  li a:hover .arrow-img-hover{
    display: inline;
      }
  nav .navbar .links  li span:hover{
    color:$hover-color;
    cursor: pointer;
    }
  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .js-sub-menu{
    display: block;
  }
  .navbar .links li .sub-menu li{
    padding: 0 22px;
  
  }
  .navbar .links li .sub-menu a{
    color: #fff;
  
    font-weight: 400;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .6px;
    cursor: pointer;
  }
  .navbar .links li .sub-menu .more-arrow{
    line-height: 40px;
  }
  .navbar .links li .htmlCss-more-sub-menu{
    /* line-height: 40px; */
  }
  .navbar .links li .sub-menu .more-sub-menu{
    position: absolute;
    top: 0;
    left: 100%;
    border-radius: 0 4px 4px 4px;
    z-index: 1;
    display: none;
  }
  .links li .sub-menu .more:hover .more-sub-menu{
    display: block;
  }
  .navbar .search-box{
    position: relative;
     height: 40px;
    width: 40px;
  }
  .navbar .search-box i{
    position: absolute;
    height: 100%;
    width: 100%;
    line-height: 40px;
    text-align: center;
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .navbar .search-box .input-box{
    position: absolute;
    right: calc(100% - 40px);
    top: 80px;
    height: 60px;
    width: 300px;
    background: #3E8DA8;
    border-radius: 6px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease;
  }
  .navbar.showInput .search-box .input-box{
    top: 65px;
    opacity: 1;
    pointer-events: auto;
    background: #3E8DA8;
  }
  .search-box .input-box::before{
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background: #3E8DA8;
    right: 10px;
    top: -6px;
    transform: rotate(45deg);
  }
  .search-box .input-box input{
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    height: 35px;
    width: 280px;
    outline: none;
    padding: 0 15px;
    font-size: 16px;
    border: none;
  }
  .navbar .nav-links .sidebar-logo{
    display: none;
  }
  .navbar .bx-menu{
    display: none;
  }
  @media (max-width:920px) {
    nav .navbar{
      max-width: 100%;
      padding: 0 25px;
    }
    nav .navbar .logo a{
      font-size: 27px;
    }
    nav .navbar .links li{
      padding: 0 10px;
      white-space: nowrap;
    }
    nav .navbar .links li a{
     
    }
  }
  .arrow-img {
    height: 18px;
  }
  .arrow-img-hover {
    margin-top: 2px;
    height: 13px;
  }
  @media (max-width:800px){
    nav{
      /* position: relative; */
    }
    .navbar .bx-menu{
      display: block;
    }
    nav .navbar .nav-links{
      position: fixed;
      top: 0;
      left: -100%;
      display: block;
      max-width: 270px;
      width: 100%;
      background:  #3E8DA8;
      line-height: 40px;
      padding: 20px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      transition: all 0.5s ease;
      z-index: 1000;
    }
    .navbar .nav-links .sidebar-logo{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .sidebar-logo .logo-name{
      font-size: 25px;
      color: #fff;
    }
      .sidebar-logo  i,
      .navbar .bx-menu{
        font-size: 25px;
        color: #fff;
      }
    nav .navbar .links{
      display: block;
      margin-top: 20px;
    }
    nav .navbar .links li .arrow{
      line-height: 40px;
    }
  nav .navbar .links li{
      display: block;
    }
  nav .navbar .links li .sub-menu{
    position: relative;
    top: 0;
    box-shadow: none;
    display: none;
  }
  nav .navbar .links li .sub-menu li{
    border-bottom: none;
  }
  .navbar .links li .sub-menu .more-sub-menu{
    display: none;
    position: relative;
    left: 0;
  }
  .navbar .links li .sub-menu .more-sub-menu li{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .links li:hover .htmlcss-arrow,
  .links li:hover .js-arrow{
    transform: rotate(0deg);
    }
    .navbar .links li .sub-menu .more-sub-menu{
      display: none;
    }
    .navbar .links li .sub-menu .more span{
      /* background: red; */
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
    }
    .links li .sub-menu .more:hover .more-sub-menu{
      display: none;
    }
    nav .navbar .links li:hover .htmlCss-sub-menu,
    nav .navbar .links li:hover .js-sub-menu{
      display: none;
    }
  .navbar .nav-links.show1 .links .htmlCss-sub-menu,
    .navbar .nav-links.show3 .links .js-sub-menu,
    .navbar .nav-links.show2 .links .more .more-sub-menu{
        display: block;
      }
      .navbar .nav-links.show1 .links .htmlcss-arrow,
      .navbar .nav-links.show3 .links .js-arrow{
          transform: rotate(180deg);
  }
      .navbar .nav-links.show2 .links .more-arrow{
        transform: rotate(90deg);
      }
  }
  @media (max-width:370px){
    nav .navbar .nav-links{
    max-width: 100%;
  } 
  }

  .sub-menu a{
    line-height: none  !important
  }

  .links{
    padding:0px;
  };

  .submenu-link{
   display: inline-flex;
    line-height:13px;
  }
  .sub-menu-link{
    line-height:15px;
    width: 100px;
    padding-bottom: 10px;
  }

  .more{
    padding:12px 20px !important;
  }
  .sub-menu-title{
    font-size: 12px;
    color: #fff;
    
  }
  .sub-menu-sub{
    font-size: 12px;
    color: #fff;
    padding-top:10px;
  }

  span.sub-menu-title{
    width: 132px;
    display: block;
    
}