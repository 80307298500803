@import "../../common/scss/_variables.scss";

.electric-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
     padding-top:50px;
    justify-content: center;
    align-items: center;
.pride-content {
    height: 25%;
    width: 100%;
    margin: 20px
}
.text-container-section{
    float: none;
    margin: auto;
  }
.heading-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: fit-content;
    align-items: flex-end;
}

    .route-phone{
        position: absolute;
        height: 57%;
        width: 35%;
        top:23%;
        right: 10%
    }
    .separator{
      margin-inline:  2px; 
    }

   
   // padding-inline: 20vh;

.icons {
    width: 10%;
    height: 20%;
    margin-bottom: 10px;
    margin-right: 5px;
}
.route-parent{
     display: flex;
  //   position: relative;
    align-items: center;
    justify-content: center;
}

.routes{
    display: flex;
    flex-direction: row;
    align-items: center;
    //  width: 65%;
     padding-left: 6px;
}

.electric-content {
    width: 100%;
    height: $medium-margin-width;
}
.lower-content{
    width: 100%;
    height: 30%;
}
.carousel-content{
    display: flex;
    flex-direction: row;
    height: 10%;
    padding-inline: 10%;
    
}
.carousel-content-1{
    display: flex;
    flex-direction: row;
    height: 10%;
    justify-content: center;
    
}
.carousel .slide{
    height: fit-content;
    background: #fff;
}

.carousel-img{
   
    max-width: 290px;
    height: 200px;
}
.carousel.carousel-slider .control-arrow {
    color: black;
    background: #1a1a1da1;
    height: min-content;
    top: 30%;
    padding: 2px;
    border-radius: 20px;
    width: 43px;
    padding-block: -21px;

}
.carousel .slider-wrapper.axis-horizontal .slider {
    padding-bottom: 15px;
}
.carousel .control-dots {
    padding: 0px;
    bottom: -14px;
}
.carousel-trail-img{
    max-width: 290px;
    height: 200px;
    
}
.carousel-route-content-mobile{
    display: none;
    // padding-inline: 15%;
}
.carousel-item-padding-40-px{
    height: 50%;
    width: 50%;
}
.react-multi-carousel-dot-list{
    padding-top: 120px;
}
.electric-foot {
    color: gray;
    font-size: 14px;
    font-weight: 500px;
    height: $medium-margin-width;
    margin-top: 20px;
}
// .carousel-item{
//     padding: 5px;
//     width: 200px;
//     max-width: 210px;
//     min-width:210px;
// }
.electric-title {
    color: gray;
    font-size: 14px;
    font-weight: 600;
}
.electric-details {
    color: gray;
    font-size: 14px;
    font-weight: 500px;
}
.second-content-electric{
    // background-color: red;
    display: flex;
    flex-wrap: wrap;
    padding-inline: 150px;
    flex-direction: column;
}
.route-details{
    padding: 15px;
    position: relative;
    // background-color: red;
}
.second-route {
    width: 50%;
}
.num-content {
    display: flex;
    flex-wrap: wrap;
}
.carousel-item-padding-10-px{
    //padding: 10px;
}

.title-route {
    font-size: 20px;
    font-weight: bolder;
    margin-top: 50px;
}
.react-multi-carousel-dot-list{

}
.carousel-container{
    padding-inline: 10px;
}
.team {
    margin-top: 10px;
}
.img-route {
    height: 100px;
    width: 100px;
}
.team-top {
    margin-top: 20px;
}
.last-para{
    padding:30px
}
.foot-details{
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
   justify-content: center;
}
.react-multiple-carousel__arrow--left{
    left:0px;
}
.react-multiple-carousel__arrow--right{
    right:0px;
}
.react-multiple-carousel__arrow{
   min-width: 30px;
   min-height: 30px;
   border-radius: 20px;
    background: rgba(0,0,0,0.1);
}
.react-multi-carousel-track {
    margin-bottom: 20px;
}
.react-multi-carousel-dot button{
    height:7px;
    width:7px;
    border-color: transparent
}
.react-multi-carousel-dot--active button {
    background: gray;
}
@media only screen and (max-width: 600px) {
    .electric-container{
        padding: 0vh;
    }
    .second-content-electric {
        padding-inline: 10px;
    }
    .second-route {
        width: 100%;
    }
    .route-phone{
        display: flex;
        position:static;
       // height: 100%;
        width: 100%;
        // top:130%;
         right: 20%
    }
    .route-parent{
        display: block;
    }
    .routes{
        width: 120%;
    }
    .electric-content {
        width: 100%;
    }
    .separator{
        display: none;
    }
    .carousel-route-content-mobile{
        display: inline;
        // padding-inline: 15%;
    }
    .carousel-route-content-desktop{
        display: none;
        // padding-inline: 15%;
    }
}
@media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
    .electric-content{
        width: 100%;
    }
    .route-phone{
        position: absolute;
        height: 40%;
        width: 30%;
        top:29%;
        right: 12%
}
// .carousel-img{
   
//     width: 80px;
//     height: 150px;
// }
.routes{
    width: 100%;
}
}
}