.popup {
  position: fixed;
  width: auto;
  height: auto;
  z-index: 9999;
}
.popup_inner {
  background-color: rgba(255, 255, 255, 1.0);
  width: 60%;
  padding-bottom: 7px;
  display: block;
  padding-top: 25px;
  padding-inline: 20px;
  margin-left: 20%;
  font-size: 16px;
  color: #4d4d4d;
  font-weight: 400;
  line-height: 27px;
  margin-top: 3%;
  border-radius: 20px;
  @media (max-width: 600px) {
    /* Adjust styles for smaller screens */
    max-height: 80vh; /* Set a maximum height for the popup on smaller screens */
    overflow-y: auto; /* Add a scrollbar if the content overflows the height */
  }
}
.button-ok{
  margin-left: 90%;
}

.pop-title{
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 600;   
  text-align: center;
}

