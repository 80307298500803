@import "../../common/scss/_variables.scss";
.common-template{
.common-content{
    width: $margin-width-eight;
    margin: auto;
  }
  .heading-sub-title{
    font-family:$MBCorpoATitleRegular;
    color: #ffffff;
    font-size: 34px;
    font-weight: 500;
    //letter-spacing: 1.5px;
  }

  .common-content p{
    width:59%;  
    font-size: 16px;
    font-weight: 600px;
    font-style: normal;
    line-height: 1.6em;
    letter-spacing: 0.3px;  
  }
  .content-layout{
    width: 45%;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 1.6em;
  }
 
  .auto-height{
    height:92px;
  }
   .element-container{
        background-repeat: round;
        height: 400px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
  }
  @media only screen and (max-width: 600px) {
  
    .common-content p{
      width:100%;    
    }
 
    .content-layout{
      width: 100%;
    }
    .common-content{
      width: 80%;
     
  
  
    }
    .element-container{
    
      background-size: cover;

      background-repeat: no-repeat !important;
      background-position: cover;
      background-position: right;
      height: 300px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }

    .element-container-health{
    
     
      height: 400px !important;
     
    }
  }

  @media screen and (min-device-width: 768px)
  and (max-device-width: 1024px) {
    .common-content p{
      width:60%;    
    }
 

    .common-content{
      width: 93%;
  
    }
    .element-container{
    
      background-repeat: round;
      height: 300px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
    }

   

    .element-container-health{
    
      height: 400px !important;
     
    }
}
}