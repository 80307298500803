
.in-news-container{
    display: inline;
    background-color: #fff;
  .two-column-layout{
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .press{
    position: relative;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
.contacts-image {
    width: 100%;
}
  }
  .press-text {
    // height: 80vh;
    // width: 100%;
    position: absolute;
  margin-top: 50px;
    display: flex;
    font-size: 37px;
    left: 20%;
    color: #fff;
    letter-spacing:0;
    //font-weight: 600;
  } 
  .address{
      color: #000000;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2em;
      letter-spacing: 0.3px;
  }
  .date {
    color: #3CB6E9;
    font-size: 13px;
    letter-spacing: 0.2px;
}
  .news-card {
    width: 100vh;
}
.img-content{
    display: flex;
    background-color: #fff;
    width: 100%;
  }
  .text-container-section{
    float: none;
    margin: auto;
  }
  .know-more-button-content{
    margin-top:25px;
    text-align: right;
  }
}