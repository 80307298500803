.innovation-container{
    display: inline-flex;
    flex-wrap: wrap;
   
    @media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
        .innovation-tile{
            width: 40vh
       }
       .innovation-container {
           width: 40vh;
       }
       .innovation-content {
        padding-left: 8vh;
    }
    .tile-contents{
        // border-width: 2px;
         border-color: #fff;
        // border-top: 2px;
         width: 44% !important;
         background-color: #fff;
     }
    }
   
    .img-content{
        display: flex;
        background-color: #fff;
    }
    .icons {
        width: 5%;
        height: 5%;
        margin-bottom: 10px;
        margin-right: 5px;
    }
    .date-content{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
.press-text {
    // height: 80vh;
    // width: 100%;
    position: absolute;
  margin-top: 50px;
    display: flex;
    font-size: 37px;
    left: 20%;
    color: #fff;
    letter-spacing:0;
   // font-weight: 600;
}
.press {
    height: 100%;
   
}
.content-title {
    font-size: 20px;
    color: #3CB6E9;
}
.innovation-title{
    font-weight: bold;
        font-size: 25px;
        letter-spacing: 0.01em;
        margin-left: 15px;

}

.para-content {
    margin: 20px
}
.about {
    margin-left: 20px;
}
.innov-text {
    font-size: 19px;
    text-align: left;
}
.para-innov {
    font-size: 11px;
     width: 100%;
    text-align: left;
    line-height: 1.4em;
}
.date-innov {
    font-size: 10px;
    // width: 35vh;
    color: gray;
}
.innovation-container {
    width: 100%;
    padding-inline: 15px;
    flex-direction: column;
}
.innovation-tile {
    height: 20%;
    width:100%;
    // position: relative;
    border-width: 2px;
    border-color: #fff;
}
.innovation-content {
    padding-left: 30vh;
    padding-top: 10vh;
}
.footer-innovation {
    color: #fff;
    margin-block: 15vh;
    width: 50%;
}
.read-content {
    font-size: 10px;
    text-align: center;
}
.tile-button {
    border:'1px solid white';
    border-color: #fff;
    border-width: 1px;
    background-color:transparent;
    flex-direction: column;
    padding: 0px;
}
.tile-contents{
   // border-width: 2px;
    border-color: #fff;
   // border-top: 2px;
    width: 26%;
    background-color: #fff;
}
.img-content{
    display: flex;
    background-color: #fff;
    width: 100%;
  }
  .text-container-section{
    float: none;
    margin: auto;
}
@media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
        .innovation-content {
            padding-left: 15vh;
        }
    }
}
@media only screen and (max-width: 600px) {
    
    .innovation-container .innovation-content {
     padding-left: 5vh;
 }  

 .tile-contents{
    // border-width: 2px;
     border-color: #fff;
    // border-top: 2px;
     width: 70% !important;
     background-color: #fff;
 }
 }