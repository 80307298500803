.press-container{
    display: inline;
    justify-content: center;
    .press{
      position: relative;
    }
   // background-color: #fff;
    height: 100%;
    //flex-wrap: wrap;
    @media only screen and  (max-device-width: 1024px) { 
        .press {
            height: 50vh;
        }
        .box{
          display: block;
          height: 400px;
        }
        .read-content{
          margin-top: 80px;
        // margin-left: 30%;
        //  width: 100px;
      }
        .tiles {
          width: 280px
        }
        .box-contents{
          width: 100%;
        }
        .contents{
         height:18vh;
         width: 80vh;
        }
        .contacts-image {
          width: 100%;
      }
    }

.queries {
    text-align: center;
    background-color: #fff;
}
.read-content{
    // margin-left: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

}
.img-content{
  display: flex;
  background-color: #fff;
  width: 100%;
}
.contents{
  color: #000000;
    font-family: "MB Corpo A Title Regular", Sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0.3px;
    padding: 10px;
    height:30px
   // width: 210vh;
}
.address{
  color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0.3px;
}
.text-container-section{
  float: none;
  margin: auto;
}
.box-contents {
  //  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  // width: 150vh;
  height: 60px;
  position: relative;
  align-content: flex-start;

}
.tiles {
    height: 140px;
    width: 200px;
    margin-right: 10px;
}
.two-column-layout{
  margin-top: 30px;
  margin-bottom: 20px;
}
.date {
    color: #3CB6E9;
    font-size: 13px;
    letter-spacing: 0.2px;
}
.legend {
    top: 10% !important;
    left: 5% !important;
    background: none !important;
    text-align: left !important;
    margin-left:0px !important;
    opacity: 1 !important;
  }
  .know-more-button-content{
    margin-top:25px;
    text-align: right;
  }
.press-text {
    // height: 80vh;
    // width: 100%;
    position: absolute;
  margin-top: 50px;
    display: flex;
    font-size: 37px;
    left: 20%;
    color: #fff;
    letter-spacing:0;
   // font-weight: 600;
    
}
.press {
    height: 100%;
   
}
.box1,
.box2,
.box3,
.box4,
.box5,
.box6,
.box7,
.box8,
.box9,
.box10 {
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  padding: 10px;
  /* height: 200px; */
  max-height: 35vh;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
}

.box {
  height:10vh;
  width: 60%;
  margin: 20px;
  padding: 10px;
  flex-direction: row;
  display: flex;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  width: 100%;
 height: 160px; 
//   max-height: 35vh;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
}
.grid {
  justify-content: center;
 // height: 100%;
  //  width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  //  background-color: #fff;
  }
  
  .grid > div {
    flex-basis: calc(80%);
  }
}