@import "../../common/scss/variables";
.about-container{
    display: inline;
    justify-content: center;
    .press-text {
        // height: 80vh;
        // width: 100%;
        position: absolute;
      margin-top: 50px;
        display: flex;
        font-size: 37px;
        left: 20%;
        color: #fff;
        letter-spacing:0;
       
        
    }
    .press {
        height: 100%;
       
    }
    .text-container-section{
        float: none;
        margin: auto;
    }
    .about-title{
        font-size: 34px;
        letter-spacing: 0.01em;
        font-weight: 500;
        margin-left: 15px;
    }
    .img-content{
        width: $full-margin-width;
        height: $full-margin-width;
       // display: inline-block;
        background-color: black;
      }
    .img-content{
        display: flex;
        background-color: #fff;
    }
    .para-content {
        margin: 20px
    }

    .first-content, .second-content {
        margin: auto;
        width: 80%;
    }
    @media screen and (min-device-width: 768px)
        and (max-device-width: 1024px) {
            .press-text{
                left: 15%;
            }
        }

        @media only screen and (max-width: 600px) {
            .first-content, .second-content {
                margin: auto;
                width: 100%;
            }

            .banner-content .press-text{
                margin-top: 2px;;
                left:2%
            }
   
        }
}