.what-mbrdi-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    .what-mbrdi-image{
        width:100%;
    }
    .press-text {
        // height: 80vh;
        // width: 100%;
      
        position: absolute;
        top:25%;
        display: flex;
        font-size: 37px;
        left: 18%;
       // font-weight: 600;
        color:#fff;
        letter-spacing:0;
        
      }
    .text-container-section{
        float: none;
        margin: auto;
        img{
            margin-bottom:20px;
            width:100%
        }

        h2{
            margin-top: 36px;
            margin-bottom: 20px;
        }
        
        p{
            // font-size:16px;
            margin: 0 0 20px;
        }
    }
    @media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
        .innovation-content {
            padding-left: 15vh;
        }
    }
    @media only screen and (max-width: 600px) {
      .press-text {
        left:5%;
        top:13%;
      }
    }

}
