@import "../../common/scss/variables";
.pride-container{
    display: inline ;
    // flex-wrap: wrap;
    width: 100%;
   // flex-direction: column;
    justify-content: center;
    .press{
        position: relative;
      }
      .press-text {
        // height: 80vh;
        // width: 100%;
        position: absolute;
      margin-top: 50px;
        display: flex;
        font-size: 37px;
        left: 20%;
        color: #fff;
        letter-spacing:0;
      //  font-weight: 600;
      }    
.pride-content {

    width: 100%;

}
.first-box{
  margin-top: 38px;
}
.text-container-section{
    float: none;
    margin: auto;
}
.img-content{
    display: flex;
    background-color: #fff;
    width: 100%;
  }
.pride-title{
    font-size: 25px;
    letter-spacing: 0.01em;
    font-weight: 500;
    margin-left: 15px;
}
.heading-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: fit-content;
    align-items: flex-end;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
    font-family: $MBCorpoATitleRegular;
}
@media only screen and (max-width: 600px) {
  .press-text{
    left: 5%;
  }
}
@media only screen and (min-device-width: 768px) and  (max-device-width: 1024px) {
  .press-text{
    left: 5%;
  }
}

}